import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/layout/seo"
import MutipleSlidesPerView from "../../components/standProj/CoverflowEffect"

import standProjMain from "../../img/standProjMain.png"

const GroupStand = () => (
  <Layout>
    <SEO title="M2MTech" />
    <div className="keicStandWrap">
      <div className="sectionWrap StandOverview">
        <p className="standProjMain">
          <img src={standProjMain} alt="" />
        </p>
        <div className="textWrap">
          <h2>
            KEIC <span>standard</span>
            <br />
            한국승강기공업협동조합 단체표준
          </h2>
          <p className="bodyText">
            (주)엠투엠테크는 2017년에 제정된 한국승강기공업협동조합의 “전기식
            엘리베이터 제작 및 조립 품질표준 “에 스마트 원격케어시스템의
            표준으로 채택되었으며, 2020년에 최신업데이트하였습니다. 엘리베이터
            이용객들의 생활안전과 시스템에 의한 유지보수 관리를 위하여 최신
            IoT(사물인터넷)기반 의 스마트 IoT 디바이스를 설치하여 시간과 장소에
            대한 제약 없이 인터넷을 통하여 24 시간 365 일 실시간으로 감시
            모니터링, 진단/제어가 가능하도록 하는 것이 엘사 시스템의 장점입니다.
          </p>
        </div>
      </div>
      <div className="sectionWrap standServices">
        <div className="textWrap">
          <h2>SPS 전기식 엘리베이터 제작 및 조립품질표준</h2>
        </div>
        <ul>
          <li>
            시스템 구성(하드웨어 및 소프트웨어) 및 특징
            <span>
              스마트 원격케어 시스템 구성은 점검/진단 대상인 엘리베이터와
              엘리베이터를 클라우드 센터와 연결하는 IoT 디바이스(엘리베이터
              제어신호 전송장치), 그리고 각 사이트에서의 엘리베이터 운행상태를
              종합적 으로 감시, 진단/제어하는 클라우드 센터로 이루어진다.
              관리자는 스마트폰, 태블릿, PC 등 다양한 단말 기기를 사용하여
              웹브라우저를 통하여 접근한다.
            </span>
          </li>
          <li>
            엘리베이터 제어반
            <span>
              엘리베이터의 제어반과 IoT 디바이스간 통신은 한국승강기공업협동조합
              단체표준(엘리베이터 단체표 준 SPS-KEIC-001:2017)에 의하여 제전된
              표준 프로토콜(승강기 공동모델 표준 프로토콜)을 사용하고 물리적으로
              CAN 또는 RS-485시리얼 통신을 지원하여 IoT 디바이스와 연결하여
              통신한다.
            </span>
          </li>
          <li>
            IoT 디바이스
            <span>
              IoT 디바이스는 엘리베이터의 제어반과 클라우드 센터와의 사이에서
              게이트웨이 역할을 하며 엘리베이터 의 운행기록 및 데이터의 저장,
              진단/제어기능을 안정적이고 독립적으로 수행하기 위하여 엘리베이터와
              일 대일로 설치하여 관리한다.
            </span>
          </li>
          <li>
            클라우드 센터
            <span>
              클라우드 센터는 인터넷 기술을 활용하여 승강기 업무 시스템의
              효율성, 비용절감 등의 장점을 통하여 업 무의 유연성, 확장성,
              안정성을 확보한다. 무엇보다도 사용자(엔지니어, 관리주체 포함)는
              클라우드 센터를 통하여 다양한 단말기를 통하여 언제 어디서나 쉽게
              승강기 유지관리 업무를 이용할 수 있어야 한다.
            </span>
          </li>
        </ul>
      </div>
      <div className="sectionWrap standGallery">
        <div className="">
          <MutipleSlidesPerView />
        </div>
      </div>
    </div>
  </Layout>
)

export default GroupStand
