import React from "react"
import Swiper from "react-id-swiper"
import screen00 from "../../img/keicDocument/01.jpg"
import screen01 from "../../img/keicDocument/02.jpg"
import screen02 from "../../img/keicDocument/03.jpg"
import screen03 from "../../img/keicDocument/04.jpg"
import screen04 from "../../img/keicDocument/05.jpg"
import screen05 from "../../img/keicDocument/06.jpg"
import screen06 from "../../img/keicDocument/07.jpg"
import screen07 from "../../img/keicDocument/08.jpg"
import screen08 from "../../img/keicDocument/09.jpg"
import screen09 from "../../img/keicDocument/10.jpg"
import screen10 from "../../img/keicDocument/11.jpg"
import screen11 from "../../img/keicDocument/12.jpg"
import screen12 from "../../img/keicDocument/13.jpg"
import "../../styles/swiper.css"

const MutipleSlidesPerView = () => {
  const params = {
    slidesPerView: 3,
    spaceBetween: 30,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  }
  return (
    <Swiper {...params}>
      <div className="keicDoc">
        <img src={screen00} alt="" />
      </div>
      <div className="keicDoc">
        <img src={screen01} alt="" />
      </div>
      <div className="keicDoc">
        <img src={screen02} alt="" />
      </div>
      <div className="keicDoc">
        <img src={screen03} alt="" />
      </div>
      <div className="keicDoc">
        <img src={screen04} alt="" />
      </div>
      <div className="keicDoc">
        <img src={screen05} alt="" />
      </div>
      <div className="keicDoc">
        <img src={screen06} alt="" />
      </div>
      <div className="keicDoc">
        <img src={screen07} alt="" />
      </div>
      <div className="keicDoc">
        <img src={screen08} alt="" />
      </div>
      <div className="keicDoc">
        <img src={screen09} alt="" />
      </div>
      <div className="keicDoc">
        <img src={screen10} alt="" />
      </div>
      <div className="keicDoc">
        <img src={screen11} alt="" />
      </div>
      <div className="keicDoc">
        <img src={screen12} alt="" />
      </div>
    </Swiper>
  )
}
export default MutipleSlidesPerView
